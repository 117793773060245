.homeFromResults {
    position: fixed;
    bottom: 3vh;
    right: 2vw;
    width: 20vw;
    height: 5vh;
    border: 3px solid #940a37;
    text-align: center;
    font-size: 4vh;
    border-radius: 30px;
    color: #940a37;
    background-color: white;
    transition: 0.3s;
    font-family: "Ubuntu";
    font-weight: bold;
}
.homeFromResults:hover {
    background-color: #940a37;
    color: white;
    cursor: pointer;
}