.wrapper {
    /* border: 1px solid black; */
    height: 100vh;
}
.leftDivDate {
    width: 50%;
    float: left;
    /* border: 1px solid red;*/
    text-align: center;
    height: 100vh;
    background-image: url(depart.jpg);
    background-size: contain;
    background-size: cover;
    font-family: "Ubuntu";
    color: white;
    -webkit-filter: grayscale(100%);
    transition: 0.5s;
    font-size: 5vw;
    font-weight: bold;
}
.rightDivDate {
    width: 50%;
    float: left;
    /* border: 1px solid green; */
    text-align: center;
    height: 100vh;
    background-image: url(arrive.jpg); 
    background-size: contain;
    background-size: cover;
    font-family: "Ubuntu";
    color: white;
    -webkit-filter: grayscale(100%);
    transition: 0.5s;
    font-size: 5vw;
    font-weight: bold;
}

.leftDivDate:hover {
    -webkit-filter: grayscale(0%);
    cursor: pointer;
}

.rightDivDate:hover {
    -webkit-filter: grayscale(0%);
    cursor: pointer;
}

.picker {
    border: 5px solid white;
    color: white;
    margin-top: 50px;
}