.subtitle {
    text-align: center;
    color: white;
    font-family: "Ubuntu";
    font-size: 20,px;
    font-weight: 700;
}
.title {
    color: white;
    text-align: left;
    font-family: "Ubuntu";
    font-weight: 600;
    font-size: 75px;
    float: left;
    padding-left: 40px;
    
}
.background {
    background-color: white;
    background-image: url(mountainPicture.jpg);
    background-size: contain;
    background-size: cover;
    width: 100%;
    height: 100vh;
}
.image-container, .blurred, .image-container>img {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
 }
.button {
    padding: 5px;
    padding-left: 60px;
    padding-right: 60px;
    border-radius: 37px 37px 37px 37px;
    text-align: center;
    color: white;
    font-family: "Ubuntu";
    font-size: 50px;
    font-weight: 700;
    background-color: #940a37;
    border: 3px solid white;
    transition: .3s;
    box-shadow: -4px 4px 5px rgb(75, 75, 75);
}
.button:hover {
    background-color: white;
    color: #940a37;
    cursor: pointer;
}
.outside {
    padding-top: 200px;
    text-align: center;
}
.imagediv {
    height: 100px;
    object-fit: contain;
    text-align: right;
    vertical-align: middle;
    background-color: #940a37;
    padding-right: 40px;
    box-shadow: -2px 2px 2px grey;
}
.image {
    height: 100%;
    vertical-align: middle;
}